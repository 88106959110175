export namespace Global {
    export const VideoPlayerSelector = '[data-video-player]';
    export const DataWoerterbuchSelector = '[data-woerterbuch]';
    export const DataInfoMessageSelector = '[data-info-message]';
    export const ErrorMessageSelector = '.form-element--error';
    export const DisableDefaultErrorMessageFocusSelector = '[data-disable-default-error-message-focus]';
    export const CheckboxGroupSelector = '.checkbox-group';
    export const CheckboxGroupChoicesSelector = '.checkbox-group__choices';
    export const FigureTableSelector = 'figure.table table:not(.labelled)';
    export const ExternalLinkSelector = 'a[target="_blank"]';
    export const TableParserServiceReadyEvent = 'table-parser-ready';
}
