export enum ModuleType {
    SERVICE = 'service',
    COMPONENT = 'component',
}

export type ModuleGroupsLoadingConfig = {
    moduleType: ModuleType;
    moduleGroups: string[][];
};

const ModuleLoaderService = {
    loadedJSModules: {},
    loadJSModules: async (config: ModuleGroupsLoadingConfig) => {
        const _asyncLoadModuleGroup = async (componentGroup: string[], groupIndex: number, moduleType: ModuleType) => {
            await Promise.all(
                componentGroup.map(async (component) => {
                    let module: any;
                    if (moduleType === ModuleType.SERVICE) {
                        module = await import(`../services/${component}.ts`);
                    } else if (moduleType === ModuleType.COMPONENT) {
                        module = await import(`../components/${component}.ts`);
                    }
                    ModuleLoaderService.loadedJSModules[component] = module;
                })
            );
        };

        const _loadModuleGroups = async (config: ModuleGroupsLoadingConfig) => {
            return new Promise<void>((resolve) => {
                const _loadModuleGroupWithIndex = async (index: number) => {
                    if (config.moduleGroups[index]) {
                        await _asyncLoadModuleGroup(config.moduleGroups[index], index, config.moduleType);
                        _loadModuleGroupWithIndex(index + 1);
                    } else {
                        resolve();
                    }
                };
                // Start loading the first group:
                _loadModuleGroupWithIndex(0);
            });
        };

        await _loadModuleGroups(config);
    },
};

export default ModuleLoaderService;
